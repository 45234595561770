import { render, staticRenderFns } from "./MemberShopAddPointLinkModal.vue?vue&type=template&id=8562effe&scoped=true"
import script from "./MemberShopAddPointLinkModal.vue?vue&type=script&lang=js"
export * from "./MemberShopAddPointLinkModal.vue?vue&type=script&lang=js"
import style0 from "./MemberShopAddPointLinkModal.vue?vue&type=style&index=0&id=8562effe&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8562effe",
  null
  
)

export default component.exports