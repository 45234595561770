<template>
  <div class="member-shop-order-products-table">
    <BaseTable :data="displayData">
      <BaseElTableColumn label="點數連結" prop="link" align="center" >
        <template slot-scope="scope">
          <div class="flex items-center" style="gap: 10px">
            <p class="w-full">{{ scope.row.link }}</p>
            <span
              class="material-icons text-primary-100 cursor-pointer"
              style="width: 24px; transform: scaleX(-1)"
              @click="copyLink(scope.row.link)"
            >
              content_copy_outlined
            </span>
          </div>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="點數序號" prop="code" align="center" />
      <BaseElTableColumn label="建立時間" prop="createdAt" align="center" />
      <BaseElTableColumn label="領取狀態" prop="status" align="center">
        <template slot-scope="scope">
          <Tag :type="linkUsedStatus(scope.row.status, 'tagType')">{{ linkUsedStatus(scope.row.status, 'label') }}</Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="領取人" prop="member" align="center" />
      <BaseElTableColumn label="領取時間" prop="usedAt" align="center" />
    </BaseTable>
  </div>
</template>

<script>
import Tag from '@/components/Tag/Tag.vue'
import { defineComponent, computed, ref } from 'vue'
import { productStatusConfig } from '@/config/memberShop'
import { get, map } from 'lodash'
import copy from 'clipboard-copy'
import { formatDate } from '@/utils/date'
import { usedPointStatusConfig } from '@/config/point'

export default defineComponent({
  name: 'MemberShopPointLinksTable',
  components: {
    Tag,
  },
  props: {
    pointLink: { type: Object, default: () => ({}) },
    tableData: { type: Array, default: () => [] },
  },
  setup (props, { emit }) {
    const loading = ref(false)

    const displayData = computed(() => {
      const linksData = props.tableData
      return map(linksData, p => {
        return {
          id: p.id,
          link: p.link,
          code: p.code,
          createdAt: p.createdAt ? formatDate(p.createdAt, 'YYYY-MM-DD HH:mm') : '-',
          status: p.status,
          member: p.MemberId ? get(p, 'Member.UserInfo.name') : '-',
          usedAt: p.usedAt ? formatDate(p.usedAt, 'YYYY-MM-DD HH:mm') : '-',
        }
      })
    })
    const copyLink = (link) => {
      try {
        copy(link)
        window.$message.success('複製連結成功！')
      } catch (error) {
        console.error('複製連結失敗:', error)
        window.$message.error('複製連結失敗！')
      }
    }
    const linkUsedStatus = (status, attr) => {
      return get(usedPointStatusConfig[status], attr)
    }
    return {
      get,
      loading,
      productStatusConfig,
      displayData,
      copyLink,
      linkUsedStatus,
    }
  },
})
</script>

<style scoped lang="postcss">

</style>
